import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import SheetsellerApp from './pages/SheetsellerApp';
import EasyReprice from './pages/EasyReprice';
import AutoReply from './pages/AutoReply';
import FullDockManager from './pages/FullDockManager';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import './styles/styles.css'; // Importa los estilos generales

const App = () => (
  <Router>
    <div className="app">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sheetseller" element={<SheetsellerApp />} />
        <Route path="/easyrepricer" element={<EasyReprice />} />
        <Route path="/fulldockmanager" element={<FullDockManager />} />
        <Route path="/autoreply" element={<AutoReply />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  </Router>
);

export default App;