import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
//import './index.css'; // Importa los estilos generales

const Root = () => {
  useEffect(() => {
    document.body.classList.add('is-loading');
    const handleLoaded = () => {
      document.body.classList.remove('is-loading');
    };
    
    window.addEventListener('load', handleLoaded);
    
    return () => {
      window.removeEventListener('load', handleLoaded);
    };
  }, []);
  
  return <App />;
};

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById('root')
);