import React, { useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../styles/TermsOfService.css';

const TermsOfService = () => {
  useEffect(() => {
    const handleLoad = () => {
      document.body.classList.add('loaded');
    };
    window.addEventListener('load', handleLoad);
    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);
  
  return (
    <div className="terms-of-service">
      <Header />
      <main className="content">
        <h2>Términos de Servicio</h2>
        <p>
          Bienvenido a <b>alia2lab</b>. Al visitar nuestro sitio y acceder a la información, recursos, servicios, productos
          y herramientas que ofrecemos, usted entiende y acepta cumplir y adherirse a los siguientes términos y
          condiciones, incluyendo el cumplimiento con las políticas de uso de la API de Amazon (incluyendo la
          <b>Política de Uso Aceptable</b> y la <b>Política de Protección de Datos</b>).
        </p>

        <h3>Uso Aceptable</h3>
        <p>
          Usted acepta utilizar nuestro sitio web y nuestras aplicaciones solo para los fines permitidos por la ley
          y por los acuerdos aplicables con Amazon. Al utilizar nuestros servicios, usted acepta no:
        </p>

        <ul>
          <li>
            Usar nuestro sitio o nuestras aplicaciones para ningún propósito ilegal, fraudulento, o que viole
            las políticas de Amazon.
          </li>
          <li>
            Acceder, almacenar o usar información de Amazon más allá de lo necesario para las
            funcionalidades específicas que ofrece nuestra plataforma, en conformidad con las políticas de
            Amazon.
          </li>
          <li>
            Compartir o distribuir información obtenida a través de la API de Amazon con terceros no
            autorizados, o para fines no permitidos por Amazon.
          </li>
          <li>
            Recopilar o almacenar información personal de otros usuarios sin su consentimiento explícito,
            ni utilizar esta información en formas que violen las leyes de privacidad o las políticas de
            Amazon.
          </li>
          <li>
            Interferir o interrumpir nuestros servicios, servidores, o las integraciones con Amazon,
            incluyendo la violación de las cuotas de la API de Amazon o la creación de múltiples cuentas de
            desarrollador con el fin de evadir estas cuotas.
          </li>
        </ul>

        <h3>Cumplimiento con las Políticas de Amazon</h3>
        <p>
          Al utilizar nuestras aplicaciones que interactúan con los servicios de Amazon, usted acepta cumplir con
          todas las políticas de Amazon, incluyendo, pero no limitado a:
        </p>
        <ul>
          <li>
            La <b>Política de Uso Aceptable de la API de Amazon</b>: Solo utilizará la API de Amazon para los
            fines permitidos por los usuarios autorizados y conforme a las actividades aceptables
            especificadas por Amazon.
          </li>
          <li>
            La <b>Política de Protección de Datos de Amazon</b>: Se asegurará de que toda la información
            recopilada y utilizada a través de la API de Amazon esté protegida y solo sea utilizada según lo
            permitido.
          </li>
        </ul>

        <h3>Privacidad</h3>
        <p>
          Su privacidad es muy importante para nosotros, y cumplimos con todas las políticas de privacidad
          aplicables a los datos de Amazon y los datos personales de nuestros usuarios. Más detalles sobre cómo
          gestionamos su información personal están disponibles en nuestro documento de <b>Política de
          Privacidad y Uso de Datos</b>.
        </p>

        <h3>Uso de Datos de Amazon</h3>
        <p>
          Usted acepta que los datos obtenidos a través de nuestras aplicaciones que utilizan la API de Amazon
          se manejarán de manera estrictamente confidencial y solo se utilizarán para los fines autorizados por
          los vendedores que han concedido acceso. No utilizará los datos de Amazon para fines comerciales
          propios, ni los agregará, venderá o compartirá con terceros no autorizados. Toda la información
          procesada a través de nuestra plataforma cumple con las políticas de Amazon y está diseñada para
          beneficiar exclusivamente al vendedor autorizado.
        </p>

        <h3>Limitación de Responsabilidad</h3>
        <p>
          Ni <b>alia2lab</b> ni sus empleados serán responsables por cualquier pérdida o daño indirecto, especial,
          accidental o consecuente que surja de o en conexión con el uso de nuestros servicios, incluyendo el
          acceso o el uso indebido de la información obtenida a través de la API de Amazon.
        </p>

        <h3>Exactitud de la Información</h3>
        <p>
        Nos esforzamos por garantizar que toda la información presentada en nuestro sitio web sea exacta y
        esté actualizada. Sin embargo, no garantizamos la exactitud, integridad o utilidad de cualquier
        información en el sitio web, incluyendo la información obtenida de la API de Amazon.
        </p>

        <h3>Suspensión del Servicio</h3>
        <p>
          Nos reservamos el derecho de suspender o cancelar su acceso a nuestro sitio web o aplicaciones si
          tenemos razones para creer que ha violado estos términos de servicio o cualquier política de Amazon
          aplicable. Cualquier violación grave, como el uso indebido de la API de Amazon, puede resultar en la
          terminación inmediata de los servicios.
        </p>

        <h3>Cambios a los Términos</h3>
        <p>
          Nos reservamos el derecho de cambiar estos términos de servicio en cualquier momento sin previo
          aviso. Usted reconoce y acepta que es su responsabilidad revisar estos términos de servicio
          periódicamente para conocer cualquier modificación. Si cualquier cambio afecta el uso de la API de
          Amazon, nos comprometemos a notificar a los usuarios de los cambios relevantes.
        </p>

        <h3>Ley Aplicable</h3>
        <p>
          Estos términos de servicio se regirán e interpretarán de acuerdo con las leyes del país en el que se
          encuentra ubicada la empresa <b>alia2lab</b>, sin dar efecto a sus principios de conflicto de leyes, y en
          conformidad con las políticas aplicables de Amazon.
        </p>

        <h3>Contáctenos</h3>
        <p>
          Si tiene alguna pregunta sobre estos términos de servicio o sobre nuestro cumplimiento con las
          políticas de Amazon, puede contactarnos a través del correo electrónico: <b>eduardoramirez@alia2lab.com</b>
        </p>
      </main>
      <Footer />
    </div>
  );
};

export default TermsOfService;