import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import '../styles/ContactForm.css';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [statusMessage, setStatusMessage] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value 
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_ashtwro', 'template_fvb2b9b', e.target, 'UAMKi13x_hKvM_5oJ')
      .then((result) => {
        console.log(result.text);
        setStatusMessage('Mensaje enviado con éxito!');
        setFormData({ name: '', email: '', message: '' });
      }, (error) => {
        console.log(error.text);
        setStatusMessage('Hubo un error al enviar el mensaje. Por favor, intenta nuevamente.');
      });
  };

  return (
    <section className="contact">
      <h2>Contacto</h2>
      {statusMessage && <p className="status-message">{statusMessage}</p>}
      <form onSubmit={handleSubmit}>
        <input 
          type="text" 
          name="name" 
          placeholder="Nombre" 
          value={formData.name}
          onChange={handleChange}
          required 
        />
        <input 
          type="email" 
          name="email" 
          placeholder="Correo Electrónico" 
          value={formData.email}
          onChange={handleChange}
          required 
        />
        <textarea 
          name="message" 
          placeholder="Mensaje" 
          value={formData.message}
          onChange={handleChange}
          required 
        />
        <button type="submit">Enviar</button>
      </form>
    </section>
  );
};

export default ContactForm;