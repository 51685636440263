import React from 'react';
import '../styles/Footer.css';

const Footer = () => (
  <footer className="footer">
    <div className="social">
      <a href="https://facebook.com">Facebook</a>
      <a href="https://twitter.com">Twitter</a>
      <a href="https://linkedin.com">LinkedIn</a>
    </div>
    <div className="legal">
      <a href="/privacy-policy">Política de Privacidad</a>
      <a href="/terms-of-service">Términos de Servicio</a>
    </div>
  </footer>
);

export default Footer;