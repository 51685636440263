import React, { useEffect, useState } from 'react';
import '../styles/AutoReply.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import logo from '../assets/images/apps/autoreply.png'; // Logo de AutoReply
import screenshot1 from '../assets/images/apps/autoreply/screenshot1.png';
import screenshot2 from '../assets/images/apps/autoreply/screenshot2.png';
import screenshot3 from '../assets/images/apps/autoreply/screenshot3.png';
import { FaExternalLinkAlt, FaTimes } from 'react-icons/fa';

const Screenshots = () => {
  useEffect(() => {
    const handleScroll = () => {
      const screenshotCards = document.querySelectorAll('.screenshot-card');
      screenshotCards.forEach((card) => {
        const rect = card.getBoundingClientRect();
        if (rect.top < window.innerHeight) {
          card.classList.add('visible');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Para activar efecto al cargar la página también

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [lightboxImage, setLightboxImage] = useState(null);

  const handleCardClick = (image) => {
    setLightboxImage(image);
    document.getElementById('lightbox').classList.add('visible');
  };

  const handleCloseLightbox = () => {
    document.getElementById('lightbox').classList.remove('visible');
    setTimeout(() => {
      setLightboxImage(null);
    }, 500); // Asegurar que la animación de salida termine antes
  };

  return (
    <>
      <section className="screenshots">
        <h2>Galería de Capturas de Pantalla</h2>
        <div className="screenshot-gallery">
          <div className="screenshot-card" onClick={() => handleCardClick(screenshot1)}>
            <img src={screenshot1} alt="Captura de Pantalla 1" />
            <p>Inicio de sesión para administradores y usuarios.</p>
          </div>
          <div className="screenshot-card" onClick={() => handleCardClick(screenshot2)}>
            <img src={screenshot2} alt="Captura de Pantalla 2" />
            <p>Distintas secciones para mejorar la integración.</p>
          </div>
          <div className="screenshot-card" onClick={() => handleCardClick(screenshot3)}>
            <img src={screenshot3} alt="Captura de Pantalla 3" />
            <p>Respuestas personalizadas basadas en la inteligencia artificial.</p>
          </div>
        </div>
      </section>
      {/* Lightbox para pantalla completa */}
      <div id="lightbox" onClick={handleCloseLightbox}>
        {lightboxImage && (
          <>
            <img src={lightboxImage} alt="Pantalla Completa" />
            <FaTimes className="close" onClick={handleCloseLightbox} />
          </>
        )}
      </div>
    </>
  );
};

const AutoReply = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const handleLoad = () => {
      setIsLoaded(true);
    };

    handleLoad();  // Asegurar que se llame al montarse también
    window.addEventListener('load', handleLoad);
    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  useEffect(() => {
    if (isLoaded) {
      document.body.classList.add('loaded');
    } else {
      document.body.classList.remove('loaded');
    }
  }, [isLoaded]);

  return (
    <div className="autoreply-app">
      <Header />
      <section className="hero" style={{ backgroundColor: '#18234c' }}>
        <div className="hero-content">
          <img src={logo} alt="AutoReply Logo" className="hero-logo" />
          <h1>Optimiza tu atención al cliente con AutoReply</h1>
          <p>Automatiza las respuestas a las preguntas de tus clientes en Mercado Libre y Amazon(próximamente).</p>
          <div className="hero-buttons">
            <a href="https://autoreplyia.vercel.app" target="_blank" rel="noopener noreferrer" className="cta-button">
              <FaExternalLinkAlt style={{ marginRight: '8px' }} />
              Visita la aplicación web
            </a>
          </div>
        </div>
      </section>
      <section className="details">
        <h2>Características Principales</h2>
        <ul className="features-list">
          <li>Respuestas automáticas basadas en IA para maximizar tu tiempo.</li>
          <li>Personaliza las respuestas según el contexto de las publicaciones.</li>
          <li>Mejora la experiencia del cliente y aumenta tus conversiones.</li>
        </ul>
      </section>
      <section className="description">
        <h2>AutoReply es tu aliado en la atención al cliente</h2>
        <div className="highlighted-text">
          <p>
            En AutoReply, entendemos que la atención rápida y precisa a los clientes es clave para impulsar tus ventas en Mercado Libre y Amazon(próximamente). Nuestro bot inteligente, impulsado por inteligencia artificial, está diseñado para responder automáticamente las preguntas de tus posibles compradores, tomando en cuenta el contexto de tus publicaciones.
          </p>
          <p>
            Ya no tendrás que preocuparte por perder una venta por no responder a tiempo. AutoReply se asegura de que tus clientes reciban la información que necesitan, de manera inmediata y precisa, sin importar la cantidad de preguntas que lleguen. Desde detalles sobre el producto hasta las condiciones de envío, nuestro sistema se adapta a cada publicación, brindando respuestas personalizadas y aumentando la satisfacción del cliente.
          </p>
          <p>
            Nuestro objetivo es que optimices tu tiempo mientras mantienes una comunicación eficiente y automatizada con tus compradores. Con AutoReply, puedes centrarte en hacer crecer tu negocio mientras nosotros nos encargamos de las preguntas, ayudándote a mejorar la experiencia del cliente y aumentar tus conversiones.
          </p>
          <p>
            Únete a la nueva era de atención al cliente automatizada con AutoReply y descubre cómo la inteligencia artificial puede trabajar por ti.
          </p>
        </div>
      </section>
      <Screenshots />
      <Footer />
    </div>
  );
};

export default AutoReply;