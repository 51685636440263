import React, { useEffect, useState } from 'react';
import '../styles/SheetsellerApp.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import logo from '../assets/images/apps/sheetseller.png';
import screenshot1 from '../assets/images/apps/sheetseller/screenshot1.png';
import screenshot2 from '../assets/images/apps/sheetseller/screenshot2.png';
import screenshot3 from '../assets/images/apps/sheetseller/screenshot3.png';
import googleWorkspaceLogo from '../assets/images/apps/sheetseller/google work.png'; // Asegúrate de tener esta imagen en tu directorio
import { FaExternalLinkAlt, FaGoogle, FaTimes } from 'react-icons/fa';

const Screenshots = () => {
  useEffect(() => {
    const handleScroll = () => {
      const screenshotCards = document.querySelectorAll('.screenshot-card');
      screenshotCards.forEach((card) => {
        const rect = card.getBoundingClientRect();
        if (rect.top < window.innerHeight) {
          card.classList.add('visible');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Para activar efecto al cargar la página también

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [lightboxImage, setLightboxImage] = useState(null);

  const handleCardClick = (image) => {
    setLightboxImage(image);
    document.getElementById('lightbox').classList.add('visible');
  };

  const handleCloseLightbox = () => {
    document.getElementById('lightbox').classList.remove('visible');
    setTimeout(() => {
      setLightboxImage(null);
    }, 500); // Asegurar que la animación de salida termine antes
  };

  return (
    <>
      <section className="screenshots">
        <h2>Galería de Capturas de Pantalla</h2>
        <div className="screenshot-gallery">
          <div className="screenshot-card" onClick={() => handleCardClick(screenshot1)}>
            <img src={screenshot1} alt="Captura de Pantalla 1" />
            <p>Vista de la plataforma web de registro y administración del usuario.</p>
          </div>
          <div className="screenshot-card" onClick={() => handleCardClick(screenshot2)}>
            <img src={screenshot2} alt="Captura de Pantalla 2" />
            <p>Reporte de uso de aplicación y suscripción del usuario.</p>
          </div>
          <div className="screenshot-card" onClick={() => handleCardClick(screenshot3)}>
            <img src={screenshot3} alt="Captura de Pantalla 3" />
            <p>Integración sencilla con Mercado Libre y Amazon(próximamente) y análisis detallado de datos</p>
          </div>
        </div>
      </section>
      {/* Lightbox para pantalla completa */}
      <div id="lightbox" onClick={handleCloseLightbox}>
        {lightboxImage && (
          <>
            <img src={lightboxImage} alt="Pantalla Completa" />
            <FaTimes className="close" onClick={handleCloseLightbox} />
          </>
        )}
      </div>
    </>
  );
};

const SheetsellerApp = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const handleLoad = () => {
      setIsLoaded(true);
    };

    handleLoad();  // Asegurar que se llame al montarse también
    window.addEventListener('load', handleLoad);
    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  useEffect(() => {
    if (isLoaded) {
      document.body.classList.add('loaded');
    } else {
      document.body.classList.remove('loaded');
    }
  }, [isLoaded]);

  return (
    <div className="sheetseller-app">
      <Header />
      <section className="hero" style={{ backgroundColor: '#53aa6e' }}>
        <div className="hero-content">
          <img src={logo} alt="SheetsellerApp Logo" className="hero-logo" />
          <h1>Potencia tu gestión de ventas con Sheetsellerapp</h1>
          <p>Obtén información personalizada sobre tus publicaciones y órdenes de venta en Google Sheets.</p>
          <div className="hero-buttons">
            <a href="https://sheetsellerappindividual.vercel.app/" target="_blank" rel="noopener noreferrer" className="cta-button">
              <FaExternalLinkAlt style={{ marginRight: '8px' }} />
              Visita Nuestro Sitio Web
            </a>
            <a href="https://workspace.google.com/marketplace/app/sheetsellerapp/74972633372" target="_blank" rel="noopener noreferrer" className="cta-button">
              <FaGoogle style={{ marginRight: '8px' }} />
              Añadir en Google Marketplace
            </a>
          </div>
        </div>
      </section>
      <section className="details">
        <h2>Características Principales</h2>
        <ul className="features-list">
          <li>Más de 30 funciones para gestionar publicaciones y órdenes de venta.</li>
          <li>Información personalizada y análisis detallado.</li>
          <li>Integración directa con Google Sheets, Mercado Libre y Amazon(próximamente).</li>
        </ul>
      </section>
      <section className="google-workspace-validation">
        <h2>Validado y Publicado en Google Workspace Marketplace</h2>
        <div className="workspace-content">
          <img src={googleWorkspaceLogo} alt="Google Workspace Marketplace Logo" className="google-workspace-logo" />
          <p>SheetsellerApp ha sido validado y publicado en Google Workspace Marketplace. Esto asegura que nuestra herramienta ha pasado por un riguroso proceso de revisión de Google, cumpliendo con sus altos estándares de seguridad, privacidad y rendimiento.</p>
          <p>Siéntete seguro al integrar SheetsellerApp en Google Sheets, aprovechando todas sus funcionalidades avanzadas con total confianza y respaldo de Google.</p>
          <a href="https://workspace.google.com/marketplace/app/sheetsellerapp/74972633372" target="_blank" rel="noopener noreferrer" className="cta-button">
            <FaGoogle style={{ marginRight: '8px' }} />
            Ver en Google Marketplace
          </a>
        </div>
      </section>
      <section className="description">
        <h2>SheetsellerApp es tu aliado inteligente</h2>
        <div className="highlighted-text">
          <p>
            Sabemos lo importante que es para los vendedores de Mercado Libre y Amazon(próximamente) tener acceso rápido y organizado a la información de su negocio. Por eso, hemos creado una solución que integra directamente con tu cuenta de Mercado Libre y Amazon(próximamente), permitiéndote consultar y analizar tus datos de ventas, órdenes, publicaciones y stock, todo desde la comodidad de Google Sheets.
          </p>
          <p>
            Nuestro objetivo es ayudarte a tomar decisiones más inteligentes, optimizar tu inventario y aumentar la productividad. SheetsellerApp es la herramienta que necesitas para tener el control total de tu ecommerce, ofreciendo una visión clara y en tiempo real de lo que está sucediendo con tu negocio. Ya no tendrás que preocuparte por descargar informes complicados o perder tiempo navegando entre plataformas. Con nosotros, toda tu información está a un clic de distancia.
          </p>
        </div>
      </section>
      <Screenshots />
      <Footer />
    </div>
  );
};

export default SheetsellerApp;