import React from 'react';
import Header from '../components/Header';
import HeroSection from '../components/HeroSection';
import ApplicationCard from '../components/ApplicationCard';
import ContactForm from '../components/ContactForm';
import Footer from '../components/Footer';
import '../styles/Home.css'; // Para los estilos específicos de la página Home

import sheetsellerImg from '../assets/images/apps/sheetseller.png';
import easyrepricerImg from '../assets/images/apps/easyreprice.png';
import fulldockmanagerImg from '../assets/images/apps/fulldock.png';
import autoreplyImg from '../assets/images/apps/autoreply.png';

const Home = () => (
  <div className="home">
    <Header />
    <HeroSection />
    <section className="about-us" id='aboutus'>
      <h2>Sobre Nosotros</h2>
      <p>
        En alia2lab, somos un equipo dedicado a desarrollar soluciones innovadoras que
        optimizan y automatizan las operaciones de los vendedores en plataformas de comercio
        electrónico como Mercado Libre y Amazon (próximamente). Nuestra misión es proporcionar herramientas que 
        empoderen a los vendedores, ayudándolos a crecer y prosperar mediante la eficiencia
        y la innovación.
      </p>
      <p>
        Nos especializamos en crear aplicaciones que permiten a los vendedores gestionar sus
        publicaciones, precios y comunicación con los clientes de manera más efectiva. 
        Desde la gestión de inventarios hasta la automatización de respuestas a clientes,
        nuestras soluciones están diseñadas para facilitar y mejorar cada aspecto de tu negocio.
      </p>
    </section>
    <section className="applications" id="apps">
      <h2 className="section-title">Nuestras Aplicaciones</h2>
      <div className="application-grid">
        <ApplicationCard
          title="SheetsellerApp"
          description="Obtén información personalizada sobre tus publicaciones y órdenes de venta en Google Sheets."
          link="/sheetseller"
          image={sheetsellerImg} // Image prop
        />
        <ApplicationCard
          title="EasyReprice"
          description="Ajusta los precios de tus publicaciones automáticamente para ganar la competencia."
          link="/easyrepricer"
          image={easyrepricerImg} // Image prop
        />
        <ApplicationCard
          title="FullDockManager"
          description="Gestiona el stock de tus publicaciones y automatiza la creación de publicaciones con stock propio."
          link="/fulldockmanager"
          image={fulldockmanagerImg} // Image prop
        />
        <ApplicationCard
          title="AutoReply"
          description="Automatiza y personaliza las respuestas a las preguntas de tus clientes"
          link="/autoreply"
          image={autoreplyImg} // Image prop
        />
      </div>
    </section>
    <ContactForm />
    <footer>
      <Footer />
    </footer>
  </div>
);

export default Home;