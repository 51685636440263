import React, { useEffect, useState } from 'react';
import '../styles/EasyReprice.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import logo from '../assets/images/apps/easyreprice.png'; // Logo de EasyReprice
import screenshot1 from '../assets/images/apps/easyrepricer/screenshot1.png';
import screenshot2 from '../assets/images/apps/easyrepricer/screenshot2.png';
import screenshot3 from '../assets/images/apps/easyrepricer/screenshot3.png';
import { FaExternalLinkAlt, FaTimes } from 'react-icons/fa';

const Screenshots = () => {
  useEffect(() => {
    const handleScroll = () => {
      const screenshotCards = document.querySelectorAll('.screenshot-card');
      screenshotCards.forEach((card) => {
        const rect = card.getBoundingClientRect();
        if (rect.top < window.innerHeight) {
          card.classList.add('visible');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Para activar efecto al cargar la página también

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [lightboxImage, setLightboxImage] = useState(null);

  const handleCardClick = (image) => {
    setLightboxImage(image);
    document.getElementById('lightbox').classList.add('visible');
  };

  const handleCloseLightbox = () => {
    document.getElementById('lightbox').classList.remove('visible');
    setTimeout(() => {
      setLightboxImage(null);
    }, 500); // Asegurar que la animación de salida termine antes
  };

  return (
    <>
      <section className="screenshots">
        <h2>Galería de Capturas de Pantalla</h2>
        <div className="screenshot-gallery">
          <div className="screenshot-card" onClick={() => handleCardClick(screenshot1)}>
            <img src={screenshot1} alt="Captura de Pantalla 1" />
            <p>Pantalla de inicio de sesión de EasyReprice</p>
          </div>
          <div className="screenshot-card" onClick={() => handleCardClick(screenshot2)}>
            <img src={screenshot2} alt="Captura de Pantalla 2" />
            <p>Gestión eficiente de estrategias de precios y márgenes</p>
          </div>
          <div className="screenshot-card" onClick={() => handleCardClick(screenshot3)}>
          <img src={screenshot3} alt="Captura de Pantalla 3" />
            <p>Monitoreo en tiempo real de los precios y competidores</p>
          </div>
        </div>
      </section>
      {/* Lightbox para pantalla completa */}
      <div id="lightbox" onClick={handleCloseLightbox}>
        {lightboxImage && (
          <>
            <img src={lightboxImage} alt="Pantalla Completa" />
            <FaTimes className="close" onClick={handleCloseLightbox} />
          </>
        )}
      </div>
    </>
  );
};

const EasyReprice = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const handleLoad = () => {
      setIsLoaded(true);
    };

    handleLoad();  // Asegurar que se llame al montarse también
    window.addEventListener('load', handleLoad);
    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  useEffect(() => {
    if (isLoaded) {
      document.body.classList.add('loaded');
    } else {
      document.body.classList.remove('loaded');
    }
  }, [isLoaded]);

  return (
    <div className="easyreprice-app">
      <Header />
      <section className="hero" style={{ backgroundColor: '#d1cfcd' }}>
        <div className="hero-content">
          <img src={logo} alt="EasyReprice Logo" className="hero-logo" />
          <h1>Mantente competitivo con EasyRepricer</h1>
          <p>Automatiza la gestión de precios y maximiza tus ventas en Mercado Libre y Amazon(próximamente).</p>
          <div className="hero-buttons">
            <a href="https://sheetsellerreprice.vercel.app/" target="_blank" rel="noopener noreferrer" className="cta-button">
              <FaExternalLinkAlt style={{ marginRight: '8px' }} />
              Visita Nuestro Sitio Web
            </a>
          </div>
        </div>
      </section>
      <section className="details">
        <h2>Características Principales</h2>
        <ul className="features-list">
          <li>Reglas automáticas de precios mínimos y máximos.</li>
          <li>Monitoreo en tiempo real y ajustes inteligentes de precios.</li>
          <li>Optimización y gestión simplificada de estrategias de precios.</li>
        </ul>
      </section>
      <section className="description">
        <h2>EasyReprice es tu aliado en la competitividad</h2>
        <div className="highlighted-text">
          <p>
            En EasyRepricer, nuestra misión es ayudarte a ser más competitivo en el dinámico mundo del ecommerce. Sabemos lo crucial que es para los vendedores de Mercado Libre y Amazon(próximamente) tener el control total sobre los precios de sus productos y responder rápidamente a los cambios en el mercado. Por eso, hemos desarrollado una herramienta que te permite establecer reglas automáticas de precios mínimos y máximos en tus publicaciones, asegurando que siempre estés un paso adelante de la competencia.
          </p>
          <p>
            EasyRepricer te ayuda a monitorear los precios en tiempo real y ajustarlos de forma inteligente para que puedas ofrecer los mejores precios sin comprometer tus márgenes. Nuestro sistema automatizado te garantiza que no solo estés al tanto de lo que hacen tus competidores, sino que también seas el primero en aprovechar cualquier oportunidad para ganar la venta.
          </p>
          <p>
            Buscamos simplificar la gestión de precios para que puedas concentrarte en hacer crecer tu negocio. Con EasyRepricer, te aseguramos una herramienta confiable y eficaz que maximiza tus ventas mientras mantienes tus estrategias de precios optimizadas y actualizadas.
          </p>
          <p>
            Descubre cómo EasyRepricer puede transformar tu forma de vender y mantenerte siempre competitivo en el mercado.
          </p>
        </div>
      </section>
      <Screenshots />
      <Footer />
    </div>
  );
};

export default EasyReprice;