import React, { useEffect, useRef } from 'react';
import '../styles/HeroSection.css';

const HeroSection = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      const handleCanPlayThrough = () => {
        setTimeout(() => video.classList.add('loaded'), 100);
      };
      video.addEventListener('canplaythrough', handleCanPlayThrough);

      return () => {
        video.removeEventListener('canplaythrough', handleCanPlayThrough);
      };
    }
  }, []);

  return (
    <section className="hero">
      <video ref={videoRef} autoPlay loop muted playsInline className="hero-video">
        <source src={require('../assets/videos/hero_video.mp4')} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="hero-content">
        <h1>Innovación y eficiencia para tu ecommerce</h1>
        <p>
          En alia2lab, somos tus aliados en la transformación digital,
          creando herramientas que potencian tu negocio y optimizan
          cada aspecto de tus operaciones en Mercado Libre y Amazon (proximamente).
        </p>
        <a href="#aboutus" className="hero-button">Conoce Más</a>
      </div>
    </section>
  );
};

export default HeroSection;