import React, { useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../styles/PrivacyPolicy.css';

const PrivacyPolicy = () => {
  useEffect(() => {
    const handleLoad = () => {
      document.body.classList.add('loaded');
    };
    window.addEventListener('load', handleLoad);
    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);
  
  return (
    <div className="privacy-policy">
      <Header />
      <main className="content">
        <h2>Política de Privacidad y Uso de Datos</h2>
        <p>
          En <b>alia2lab</b>, accesible desde https://www.alia2lab.com/, una de nuestras principales prioridades es la
          privacidad de nuestros visitantes y usuarios de nuestras aplicaciones. Este documento de Política de
          Privacidad y Uso de Datos describe los tipos de información que recopilamos y cómo gestionamos y
          utilizamos la información relacionada con nuestras aplicaciones, en conformidad con las secciones 4.4
          y 4.5 de la Política de Uso Aceptable de Amazon.
        </p>

        <h3>Información que Recopilamos</h3>
        <p>
          Recopilamos información que usted nos proporciona cuando se registra en nuestro sitio o utiliza
          nuestras aplicaciones. Esto incluye, pero no se limita a, información relacionada con las cuentas de
          Amazon de nuestros usuarios, datos de ventas, inventario, órdenes, y precios. La recopilación de esta
          información tiene como único propósito proporcionar las funcionalidades que ofrecen nuestras
          aplicaciones, como la gestión de inventarios, seguimiento de órdenes y la optimización de precios.
        </p>

        <h3>Uso de la Información Recopilada</h3>
        <p>
          La información que recopilamos a través de nuestras aplicaciones se utiliza exclusivamente para
          proporcionar las funcionalidades específicas de cada una de nuestras herramientas. No utilizamos,
          vendemos, compartimos ni comercializamos los datos de Amazon para fines propios o de terceros. Los
          datos obtenidos de las cuentas de Amazon se procesan únicamente para:
        </p>
        <ul>
          <li>Optimizar la gestión de inventarios y órdenes.</li>
          <li>Proporcionar informes y análisis que permitan a los vendedores de Amazon mejorar su estrategia de ventas.</li>
          <li>Automatizar procesos de ajuste de precios dentro de las cuentas de Amazon del usuario, según las configuraciones predefinidas.</li>
        </ul>
        <p>
          Todos los datos obtenidos a través de la API de Amazon son utilizados de acuerdo con los términos y
          condiciones establecidos por Amazon, y para beneficio exclusivo del vendedor autorizado.
        </p>

        <h3>Protección de los Datos de Amazon</h3>
        <ul>
          <li>
            Implementamos medidas de seguridad avanzadas para proteger toda la información obtenida a
            través de la API de Amazon. Esto incluye el uso de encriptación tanto en tránsito como en
            reposo, así como controles de acceso estrictos para limitar quién puede acceder a los datos.
          </li>
          <li>
            <b>No agregamos ni compartimos los datos obtenidos</b> de múltiples usuarios o vendedores de
            Amazon. Cada cuenta y sus datos se gestionan de forma aislada, y los datos nunca se combinan
            o analizan para beneficios comerciales propios o de terceros.
          </li>
          <li>
            Nos comprometemos a cumplir con las políticas de protección de datos de Amazon,
            asegurándonos de que la información de los clientes y vendedores se utilice solo para los fines
            autorizados.
          </li>
        </ul>

        <h3>Divulgación a Terceros</h3>
        <p>
          No compartimos, vendemos ni intercambiamos datos personales ni información de las cuentas de
          Amazon con terceros, a menos que sea necesario para proporcionar las funcionalidades específicas de
          nuestras aplicaciones. En estos casos, trabajamos únicamente con terceros de confianza que cumplan
          con nuestras estrictas políticas de seguridad y privacidad, asegurándonos de que la información
          permanezca confidencial.
        </p>

        <h3>Cumplimiento con las Políticas de Amazon</h3>
        <p>
        En <b>alia2lab</b>, cumplimos estrictamente con la Política de Uso Aceptable y la Política de Protección de
        Datos de Amazon. No utilizamos ni compartimos información de Amazon para fines no permitidos,
        como análisis no autorizados, agregación de datos de diferentes vendedores o cualquier actividad que
        infrinja los acuerdos entre Amazon y sus usuarios autorizados.
        </p>

        <h3>Acceso y Modificaciones</h3>
        <p>
          Si necesita actualizar o eliminar cualquier información de su cuenta en nuestra plataforma, puede
          contactarnos a través del correo electrónico: eduardoramirez@alia2lab.com. Nos aseguraremos de que
          cualquier solicitud de acceso o modificación de información sea procesada en conformidad con las
          políticas de seguridad de Amazon
        </p>

        <h3>Consentimiento</h3>
        <p>
          Al utilizar nuestras aplicaciones, usted consiente nuestra Política de Privacidad y Uso de Datos, y
          acepta que la información de su cuenta de Amazon será utilizada únicamente para los fines específicos
          detallados en nuestras aplicaciones, de acuerdo con las políticas de Amazon.
        </p>

        <h3>Contáctenos</h3>
        <p>
          Si tiene alguna pregunta o inquietud acerca de esta Política de Privacidad y Uso de Datos, puede
          contactarnos en: <b>eduardoramirez@alia2lab.com</b>
        </p>
      </main>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;