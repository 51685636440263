import React from 'react';
import '../styles/ApplicationCard.css';

const ApplicationCard = ({ title, description, link, image }) => (
  <div className="application-card">
    <img src={image} alt={title} className="application-image" />
    <h3>{title}</h3>
    <p className="app-description">{description}</p>
    <a href={link} className="app-button">Ver Más</a>
  </div>
);

export default ApplicationCard;