import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Header.css';

const Header = () => (
  <header className="header">
    <div className="logo">
      <Link to="/">alia2lab</Link>
    </div>
    <nav className="nav">
      <ul>
        <li><Link to="/">Home</Link></li>
        <li>
          <a href="#applications">Aplicaciones</a>
          <ul className="submenu">
            <li><Link to="/sheetseller">SheetsellerApp</Link></li>
            <li><Link to="/easyrepricer">EasyReprice</Link></li>
            <li><Link to="/fulldockmanager">FullDockManager</Link></li>
            <li><Link to="/autoreply">AutoReply</Link></li>
          </ul>
        </li>
      </ul>
    </nav>
    <div className="cta">
    </div>
  </header>
);

export default Header;