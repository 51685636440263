import React, { useEffect, useState } from 'react';
import '../styles/FullDockManager.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import logo from '../assets/images/apps/fulldock.png'; // Logo de FullDock Manager
import screenshot1 from '../assets/images/apps/fulldock/screenshot1.png';
import screenshot2 from '../assets/images/apps/fulldock/screenshot2.png';
import screenshot3 from '../assets/images/apps/fulldock/screenshot3.png';
import { FaExternalLinkAlt, FaTimes } from 'react-icons/fa';

const Screenshots = () => {
  useEffect(() => {
    const handleScroll = () => {
      const screenshotCards = document.querySelectorAll('.screenshot-card');
      screenshotCards.forEach((card) => {
        const rect = card.getBoundingClientRect();
        if (rect.top < window.innerHeight) {
          card.classList.add('visible');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Para activar efecto al cargar la página también

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [lightboxImage, setLightboxImage] = useState(null);

  const handleCardClick = (image) => {
    setLightboxImage(image);
    document.getElementById('lightbox').classList.add('visible');
  };

  const handleCloseLightbox = () => {
    document.getElementById('lightbox').classList.remove('visible');
    setTimeout(() => {
      setLightboxImage(null);
    }, 500); // Asegurar que la animación de salida termine antes
  };

  return (
    <>
      <section className="screenshots">
        <h2>Galería de Capturas de Pantalla</h2>
        <div className="screenshot-gallery">
          <div className="screenshot-card" onClick={() => handleCardClick(screenshot1)}>
            <img src={screenshot1} alt="Captura de Pantalla 1" />
            <p>Gestión de stock y publicaciones de manera automatizada.</p>
          </div>
          <div className="screenshot-card" onClick={() => handleCardClick(screenshot2)}>
            <img src={screenshot2} alt="Captura de Pantalla 2" />
            <p>Manejo eficiente de inventario con FullDock Manager.</p>
          </div>
          <div className="screenshot-card" onClick={() => handleCardClick(screenshot3)}>
            <img src={screenshot3} alt="Captura de Pantalla 3" />
            <p>Panel de control para establecer limites.</p>
          </div>
        </div>
      </section>
      {/* Lightbox para pantalla completa */}
      <div id="lightbox" onClick={handleCloseLightbox}>
        {lightboxImage && (
          <>
            <img src={lightboxImage} alt="Pantalla Completa" />
            <FaTimes className="close" onClick={handleCloseLightbox} />
          </>
        )}
      </div>
    </>
  );
};

const FullDockManager = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const handleLoad = () => {
      setIsLoaded(true);
    };

    handleLoad();  // Asegurar que se llame al montarse también
    window.addEventListener('load', handleLoad);
    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  useEffect(() => {
    if (isLoaded) {
      document.body.classList.add('loaded');
    } else {
      document.body.classList.remove('loaded');
    }
  }, [isLoaded]);

  return (
    <div className="fulldockmanager-app">
      <Header />
      <section className="hero" style={{ backgroundImage: 'linear-gradient(135deg, #6b5153, #61667c, #0d141b, #1e2d48)' }}>
        <div className="hero-content">
          <img src={logo} alt="FullDock Manager Logo" className="hero-logo" />
          <h1>Gestiona tu stock con FullDock Manager</h1>
          <p>Simplifica la gestión de inventarios y mantén tus productos siempre disponibles.</p>
          <div className="hero-buttons">
            <a href="https://fulldockmanager.vercel.app/" target="_blank" rel="noopener noreferrer" className="cta-button">
              <FaExternalLinkAlt style={{ marginRight: '8px' }} />
              Visita Nuestro Sitio Web
            </a>
          </div>
        </div>
      </section>
      <section className="details">
        <h2>Características Principales</h2>
        <ul className="features-list">
          <li>Gestión eficiente de inventarios y publicaciones.</li>
          <li>Automatización de modalidades logísticas como full y cross dock.</li>
          <li>Manejo masivo y ágil del stock para mantener tus productos siempre disponibles.</li>
        </ul>
      </section>
      <section className="description">
        <h2>FullDock Manager es tu aliado en la gestión de inventarios</h2>
        <div className="highlighted-text">
          <p>
            En FullDock Manager, nos enfocamos en simplificar la gestión de inventario para los vendedores en Mercado Libre y Amazon(próximamente). Entendemos lo complejo que puede ser administrar múltiples publicaciones y mantener un control preciso del stock, especialmente cuando se manejan diferentes modalidades logísticas como full o cross dock. Por eso, hemos desarrollado una herramienta que te permite gestionar tu stock de manera eficiente y automatizada.
          </p>
          <p>
            Con FullDock Manager, puedes ajustar tus publicaciones según los parámetros logísticos que prefieras, ya sea para pausarlas, activarlas o incluso duplicarlas de manera masiva con solo unos clics. Nuestro sistema te da el control total para que mantengas tus productos siempre disponibles y optimizados, sin complicaciones y con un manejo ágil del inventario.
          </p>
          <p>
            Nuestro compromiso es ofrecerte una solución que te permita enfocarte en lo que realmente importa: hacer crecer tu negocio. Con FullDock Manager, tu gestión de stock nunca ha sido tan fácil y efectiva.
          </p>
          <p>
            Únete a nosotros y experimenta una nueva forma de administrar tus publicaciones y stock en Mercado Libre y Amazon(próximamente).
          </p>
        </div>
      </section>
      <Screenshots />
      <Footer />
    </div>
  );
};

export default FullDockManager;